import React, { Suspense, useContext } from "react";
import { Button, Col, Form, Input, Row, Modal, Typography } from "antd";
import { useMutation } from "@tanstack/react-query";
import { AAUTH } from "../../../apis/auth";
import { useTranslation } from "react-i18next";
import Loading from "../../../components/Loading";
import CustomPhoneIcon from "../../../components/Icons/CustomPhoneIcon";
import HeaderLogin from "../../../components/HeaderLogin";
import { beeConfigs } from "../../../configs/oeg.configs";
import CustomLockIcon from "../../../components/Icons/CustomLockIcon";
import { handleFetchApi } from "../../../configs/apiRequest";
import CustomDangerIcon from "../../../components/Icons/CustomDangerIcon";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../../stores";
import { linkToDashboard, saveUserSession } from "./helper";

function Login() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { loading, setLoading, setMe } = useContext(StoreContext);

    const mutation = useMutation({
        mutationFn: (values) => {
            setLoading(true);
            let data = {
                username: values?.username?.toLowerCase()?.trim(),
                password: values?.password?.replace(/ /g, ""),
            };

            return handleFetchApi(AAUTH.login({ data }));
        },
        onSuccess: async (response) => {
            if (response?.status !== "S0000") {
                setLoading(false);
                return Modal.error({
                    title: t("login_error_title"),
                    icon: <CustomDangerIcon />,
                    content: t("login_error_msg"),
                    centered: true,
                    okText: t("login_error_okbtn"),
                });
            }
            saveUserSession({ response, t, setMe });
            linkToDashboard({ me: response.data });
            return;
        },
        onError: (err) => {
            Modal.error({
                title: err?.message,
                content: (
                    <>
                        {t("connection_error")}
                        <br />
                        {t("check_network_or_click")} <a href={`${window.location.origin}/login`}>{t("here")}</a>
                    </>
                ),
            });
        },
    });

    return (
        <div className="cb__login">
            <Suspense fallback={<Loading />}>
                <Row className="cb__login__content container" justify={"space-evenly"} align={"stretch"}>
                    <HeaderLogin />
                    <Col xs={24} sm={12} md={12} lg={14} xl={11} className="flex__center__center flex__column">
                        <Typography.Title className="welcome_title" level={3}>
                            {t("welcome_to")} {beeConfigs?.brandName}!
                        </Typography.Title>
                        <Form requiredMark={false} layout="vertical" name="basic" onFinish={(values) => mutation.mutate(values)} autoComplete="off">
                            <Form.Item label={t("username")} name="username" rules={[{ required: true, message: t("pls_username") }]}>
                                <Input size="large" placeholder={t("plh_username")} />
                            </Form.Item>

                            <Form.Item label={t("password")} name="password" rules={[{ required: true, message: t("pls_password") }]}>
                                <Input.Password maxLength={32} size="large" prefix={<CustomLockIcon />} placeholder={t("plh_password")} />
                            </Form.Item>
                            <br />
                            <Button size="large" loading={loading} type="primary" htmlType="submit">
                                {t("login")}
                            </Button>
                            <br />
                            <Button type="link" size="small" onClick={() => navigate("/forgot_password")}>
                                {t("forgot_pwd")}
                            </Button>
                        </Form>
                        <Typography.Text type="secondary" className="hotline flex__center__center">
                            <CustomPhoneIcon /> {t("hotline")} <b>0782 469 899</b>
                        </Typography.Text>
                    </Col>
                    <Col xs={0} sm={11} md={11} lg={10} xl={10} style={{ margin: "auto 0" }} className="flex__center__center">
                        <img src={beeConfigs?.login} alt="mainblog" />
                    </Col>
                </Row>
            </Suspense>
        </div>
    );
}

export default Login;
