import React, { Suspense, useContext, useState } from "react";
import Loading from "../../../components/Loading";
import { Button, Col, Form, Input, message, Modal, notification, Row, Typography } from "antd";
import { StoreContext } from "../../../stores";
import HeaderLogin from "../../../components/HeaderLogin";
import CustomPhoneIcon from "../../../components/Icons/CustomPhoneIcon";
import { useMutation } from "@tanstack/react-query";
import { AAUTH } from "../../../apis/auth";
import { handleFetchApi } from "../../../configs/apiRequest";
import ModalConfirmSuccess from "./ModalConfirmSuccess";
import { useTranslation } from "react-i18next";
import { StatusCodeEnums } from "../../../configs/statusCodeEnums";
import { useNavigate } from "react-router-dom";
import CustomDangerIcon from "../../../components/Icons/CustomDangerIcon";
import { beeConfigs } from "../../../configs/oeg.configs";

function ForgotPassword() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { loading, setLoading } = useContext(StoreContext);
    const [email, setEmail] = useState();
    const [isOpenModal, setIsOpenModal] = useState(false);

    const requestSendEmail = useMutation({
        mutationFn: () => {
            setLoading(true);
            return handleFetchApi(
                AAUTH.forgotPassword({
                    data: {
                        username: email?.username,
                        callback_url: `${window.location.origin}/set_new_password`,
                    },
                })
            );
        },
        onSuccess: () => {
            setLoading(false);
            notification.success({ message: t("email_sent_check") });
            setIsOpenModal(true);
        },
    });

    const checkEmailByUsername = useMutation({
        mutationFn: (values) => {
            setLoading(true);
            return handleFetchApi(AAUTH.checkEmail({ username: values.username?.toLowerCase()?.trim() }));
        },
        onSuccess: (response, values) => {
            setLoading(false);
            if (response.status !== "S0000") {
                notification.error({ message: t(response.status) });
                return;
            }
            setEmail({
                username: values?.username.toLowerCase(),
                email: response?.data?.address,
            });
        },
        onError: () => {
            setLoading(false);
            Modal.error({
                title: t("invalid_email_title"),
                content: t("invalid_email"),
                centered: true,
                icon: <CustomDangerIcon />,
            });
        },
    });

    const SubmitUsername = () => (
        <>
            <Typography.Title className="welcome_title" level={3}>
                {t("forgot_password_question")}
            </Typography.Title>
            <Typography.Text type="secondary">{t("email_will_be_sent")}</Typography.Text>
            <Form requiredMark={false} layout="vertical" name="basic" onFinish={checkEmailByUsername.mutate} autoComplete="off">
                <Form.Item label={t("username")} name="username" rules={[{ required: true, message: t("pls_username") }]}>
                    <Input size="large" placeholder={t("plh_username")} />
                </Form.Item>
                <Button size="large" loading={loading} type="primary" htmlType="submit">
                    {t("continue")}
                </Button>
            </Form>

            <Button type="link" onClick={() => navigate("/login")} size="small">
                {t("back")}
            </Button>
        </>
    );

    const ConfirmEmail = () => (
        <>
            <Typography.Title className="welcome_title" level={3}>
                {t("forgot_password_question")}
            </Typography.Title>
            <Typography.Text type="secondary">{t("registered_email_is", { email: <b>{email?.email}</b> })}</Typography.Text>
            <Typography.Title level={3} className="email_title">
                {email?.email}
            </Typography.Title>
            <Button size="large" loading={loading} type="primary" onClick={requestSendEmail.mutate}>
                {t("confirm")}
            </Button>
            <Button type="text" onClick={() => setEmail(null)}>
                {t("not_my_email")}
            </Button>
        </>
    );

    return (
        <div className="cb__login">
            <Suspense fallback={<Loading />}>
                <Row className="cb__login__content container" justify="space-evenly" align="stretch">
                    <HeaderLogin />
                    <Col xs={0} sm={8} md={8} lg={9} xl={10} className="flex__center__center flex__column">
                        {email ? <ConfirmEmail /> : <SubmitUsername />}
                        <Typography.Text type="secondary" className="hotline flex__center__center">
                            <CustomPhoneIcon /> {t("hotline")} <b>0782 469 899</b>
                        </Typography.Text>
                    </Col>
                    <Col xs={0} sm={8} md={8} lg={8} xl={10} style={{ margin: "auto 0" }} className="flex__center__center">
                        <img src={beeConfigs.forgotPassword} alt="mainblog" />
                    </Col>
                </Row>
            </Suspense>
            {isOpenModal && <ModalConfirmSuccess email={email} reSendEmail={requestSendEmail.mutate} />}
        </div>
    );
}

export default ForgotPassword;
