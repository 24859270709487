import React from "react";
import { Segmented } from "antd";
import vietnam from "../../assets/images/vietnam.png";
import uk from "../../assets/images/uk.png";
import { useTranslation } from "react-i18next";
import { beeConfigs } from "../../configs/oeg.configs";

function HeaderLogin(props) {
    const imageStyle = {
        height: "30px",
        width: "auto",
        marginRight: "5px",
    };
    const { i18n  } = useTranslation();


    return (
        <header className="cb__login__header flex__between__center">
            <img src={beeConfigs.logoWithText} alt="test" />
            <Segmented
                onChange={(e) => {
                    i18n.changeLanguage(e)
                }}
                defaultValue={localStorage.getItem('i18nextLng') ?? 'vn'}
                options={[
                    {
                        label: (
                            <>
                                <img style={imageStyle} src={vietnam} alt="vietname" /> Tiếng Việt
                            </>
                        ),
                        value: "vn",
                    },
                    {
                        label: (
                            <>
                                <img style={imageStyle} src={uk} alt="uke" /> English
                            </>
                        ),
                        value: "en",
                    },
                ]}
            />
        </header>
    );
}

export default HeaderLogin;
